
import request from '../../helpers/requests';


export const Tracking = async ({
    menu_id,
    type,
    timing = 0
}) => {
    const res = await request.post(`/register/kpi`, {
        menu_id, type, timing
    });

    return res;
};


