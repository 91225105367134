import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {stylesLayout} from './styles'
import {constants} from '../../../config/constants';
import Margin from '../../../components/shared/margin';
import Divider from '../../../components/shared/divider';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/socials/facebook_white.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/socials/instagram_white.svg';
import { ReactComponent as Menu } from '../../../assets/icons/menu.svg';
import ItemCategorie from './itemCategorie';
import ChangeLang from '../../../components/changeLang';
import themes from '../../../themes';
import { useMediaQuery } from 'react-responsive';


  const Index = ({ children, backButton = false }) => {
    const {
      allmenu
    } = useSelector((state) => state.initLoad);
  
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const modeDark = useSelector((state) => state.modeDark).modeDark;
    const styles = stylesLayout(modeDark);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [categories, setCategories] = useState([]);
    const [openNav, setOpenNav] = useState(false);


    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


      let responsiveDeviceNav;
      let responsiveDeviceChild;
      let iconMenu


      let openNavStyle = {
          left: '0px',
          width: '40%',
          transition: 'left 0.3s, width 0.3s',
      }
      
      if (windowWidth >= 1024) {
        responsiveDeviceNav = {};
        responsiveDeviceChild = {};
        iconMenu = {display: 'none'}
      } else if (windowWidth >= 768) {
        responsiveDeviceNav = {};
        responsiveDeviceChild = {};
        iconMenu = {display: 'none'}
      } else {
        responsiveDeviceNav = {
          position: 'absolute',
          left: '-200px',
          width: '0px',
          transition: 'left 0.3s, width 0.3s',
        };
        responsiveDeviceChild = {
          width: '100%'
        }

        iconMenu = {display: 'block'}
      }


    useEffect(()=>{
      setCategories(allmenu.data);
    }, [allmenu]);


    let back;

      if (allmenu.restaurant_id === 6) {
        back = `url(${require('./assets/Parkview-38.png')})`;
      } else if (allmenu.restaurant_id === 5) {
        back = `url(${require('./assets/WEB_DA-06.jpg')})`;
      } else {
        back = `url(${require('./assets/DSC05941APP4b.jpg')})`;
      }

    return (
      <div className='backgroundlayout' style={{
        flex: 1,
        backgroundColor: modeDark === 'dark' ? themes.colors.black : themes.colors.white,
        backgroundImage: back,
        backgroundSize: 'cover',
        height: '100%',
        window: '100%'
      }}>
        <div style={styles.content}>
          {/*<button onClick={()=>setOpenNav(!openNav)} style={{...styles.iconMenuMobile, ...iconMenu}}>
            <Menu />
          </button>*/}
          

          <div style={{...styles.contentChild, ...responsiveDeviceChild}}>
            {/*<div style={styles.contentTheme}>
              <a onPress={()=>setOpenTheme(!openTheme)}>
                <LayoutsWhite width={38} height={38}/>
              </a>
              <Margin left={15} />
              {/*openTheme && 
                  <div style={styles.contentChangeTheme}>
                    <a 
                      style={styles.btnTheme}
                      onPress={()=>store.dispatch(theme('theme1'))}
                    >
                      <Image 
                        style={styles.imagetheme}
                        source={require("/assets/themes/theme1.png") } />
                        <Text style={[commonStyles.txt_neutral, styles.textTheme]}>Theme1</Text>
                    </a>
                    <a onPress={()=>store.dispatch(theme('theme2'))}>
                      <Image 
                        style={styles.imagetheme}
                        source={require("@app/assets/themes/theme2.png") } />
                        <Text style={[commonStyles.txt_neutral, styles.textTheme]}>Theme2</Text>
                    </a>

                    <a onPress={()=>store.dispatch(theme('theme3'))}>
                      <Image 
                        style={styles.imagetheme}
                        source={require("@app/assets/themes/theme3.png") } />
                        <Text style={[commonStyles.txt_neutral, styles.textTheme]}>Theme3</Text>
                    </a>
                    
                  </div>
            </div>*/}
            {children}
          </div>
        </div>
      </div>
     
    );
  };

export default Index;