import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useInit} from '../hooks/useInit';
import i18next from 'i18next';
// importar tus componentes necesarios
import SplashScreen from '../screens/splash';
import LoginScreen from '../screens/signin/login';
import HomeScreen from '../screens/menu';
import MenuListScreen from '../screens/menu/list';
import MenuDetailScreen from '../screens/menu/detail';
// import Footer from '@app/components/footer';

const RootNavigator = () => {
  useInit();
  i18next.changeLanguage('es')
  const {
    isFirstLoad, 
    isSingin,
  } = useSelector((state) => state.initLoad);

  return (
    <Router>
      <Routes>
        {isFirstLoad ? (
           <Route path="/*" element={<SplashScreen />} />
        ) : (
          <>
            {isSingin ? (
              <>
                <Route path="/:hash" element={<HomeScreen />} />
                <Route path="MenuList/:hash/:categorie_id" element={<MenuListScreen />} />
                <Route path="MenuDetail/:hash" element={<MenuDetailScreen />} />
              </>
            ) : (
              <Route path="/*" element={<LoginScreen />} />
            )}
          </>
        )}
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
};

export default RootNavigator;
