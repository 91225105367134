import React from 'react';
import { Link } from 'react-router-dom';
import {commonStyles as stylesGeneral} from '../../../../styles/common';
import {useSelector} from 'react-redux';
import Margin from '../../../../components/shared/margin';
import {useTranslation} from 'react-i18next';
import translate from '../../../..//helpers/translate';
import {styles} from './styles';
import {constants} from '../../../../config/constants';

function Index({item}) {
    
    const {i18n} = useTranslation();
    const langEng = i18n.language === 'en' ?? false;
    const {modeDark: darkMode} = useSelector((state) => state.modeDark);
    const urlSegments = window.location.pathname.split('/');
    const hash = urlSegments[urlSegments.length - 1];

    const commonStyles = stylesGeneral(darkMode);

    return (
        <Link
            to={`/MenuList/${hash}/${item.id}`}
            state={{ categorie: item }}
            style={{
                ...styles.itemcategorie,
                ...(darkMode === 'dark' ? styles.itemcategorieDark : styles.itemcategorieLight),
                ...(item.icon === '' ? { justifyContent: 'center' } : {}),
            }}
        >
           {/*item.icon !== '' &&
                <>
                    <img
                        style={styles.icon}
                        src={`${constants.pathIconCategories}/${item.icon}`}
                    />
                    <Margin left={15}/>
                </>
           */}

            <p  style={{...commonStyles.txt_p_regular, ...styles.label, margin: 0}}>
                {
                    langEng ? item.name : translate(i18n.language, item.language, item.name)
                }
            </p>
          
        </Link>
    );
}

export default Index;