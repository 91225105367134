import React, {useState} from 'react';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {styles} from './styles';
import {commonStyles as stylesCommon} from '../../styles/common';

function Index() {
    const {t, i18n} = useTranslation();
    const [open, setOpen] = useState(false);
    const commonStyles = stylesCommon(); 
    const lang = i18n.language;

    return (
       <div style={styles.content}>
            {open &&
                <div style={{
                    ...styles.dropdown,
                    ...{
                        zIndex: 999
                    }
                }}>
                    <div 
                        onClick={
                            ()=> [
                                i18next.changeLanguage('es'),
                                setOpen(!open)
                            ]
                        } 
                        style={styles.dropdownItem}
                    >
                        <img 
                            style={styles.iconsSmall}
                            src={require('../../assets/icons/spanish.png')} />
                        <p style={{...commonStyles.txt_neutral, ...styles.text}}>{t('spanish')}</p>
                    </div>
                    <div 
                        onClick={
                            ()=> [
                                i18next.changeLanguage('en'),
                                setOpen(!open)
                            ]
                        }
                        style={styles.dropdownItem}
                    >
                         <img 
                            style={styles.iconsSmall}
                            src={require('../../assets/icons/english.png')}/>
                        <p style={{...commonStyles.txt_neutral, ...styles.text}}>{t('english')}</p>
                    </div>
                </div>
            }

            <div onClick={()=> {setOpen(!open)}} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingInline: 10,
                
            }}>
              <img 
                style={styles.icons}
                src={
                    lang === 'en' ?
                        require('../../assets/icons/english.png')
                        : 
                        require('../../assets/icons/spanish.png')
                } />

                <p style={{...commonStyles.txt_neutral, ...styles.text}}>
                    {lang === 'en' ? (
                        'ENGLISH'
                    ): (
                        'ESPAÑOL'
                    )}
                </p>

            </div>
            
       </div>
    );
}

export default Index;

