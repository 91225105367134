export const styles = {
    contentMenu: {
      flex: 1,
      borderWidth: 1,
      justifyContent: 'center',
      backgroundColor: 'black'
    },
  
    itemCategorie: {
      textAlign: 'center'
    },
    
    itemcategorie: {
      flexDirection: 'row',
      alignItems: 'center',
      width: 170,
      height: 65.371,
      padding: '25px',  
      borderRadius: 5
    },
  
    contentItemsCategories: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
  
    itemcategorieDark: {
      backgroundColor: '#7e747466'
    },
  
    itemcategorieLight: {
      backgroundColor: '#7e747417'
    },
  
    contentEvent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
  
    itemEvent: {
      width: '49%',
      height: 100
    },
  
    banner: {
      width: '100%',
      height: 100,
      objectFit: 'contain' 
    },
    logo: {
      width: '100%',
      height: 141,
      justifyContent: 'center',
      objectFit: 'contain',  
    },
  };
  