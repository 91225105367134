import React, {useState, useEffect} from 'react';
import LayoutContent from '../../../../components/layouts/content2';
import Margin from '../../../../components/shared/margin';
import {styles} from './styles'; 
import ChangeLang from '../../../../components/changeLang';
import { useSelector } from 'react-redux';
import ItemCategorie from '../../../../components/layouts/content2/itemCategorie';
import {constants} from '../../../../config/constants';
const Index = () => {
  const [categories, setCategories] = useState([]);

  const {
    allmenu
  } = useSelector((state) => state.initLoad);


  useEffect(()=>{
    setCategories(allmenu.data);
  }, [allmenu]);

  return (
    <LayoutContent>
      <div className="scroll-view" style={{ zIndex: -1 }} nestedScrollEnabled={true}>
        <div style={{ padding: '0 20px' }}>
          <Margin top={20}/>

          <img
              style={styles.logo}
              src={`${constants.pathLogoRestaurant}/${allmenu.logo}`}
              alt="Logo"
          />  <Margin top={20}/>

          <div style={{
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}>
            <ChangeLang />
          </div>

          <Margin top={20}/>



          <center>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center',  width: '100%', alignSelf: 'center'}}>
            {categories.map((item, key)=>{
                return <div key={key} style={{
                  padding: 15,
                  margin: 10,
                  width: 110,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid white',
                  borderRadius: 5
                }}>
                    <ItemCategorie item={item} />
                </div>
            })}
          </div>
          </center>

          <Margin top={20}/>
        </div>
        <Margin top={100} />
      </div>
    </LayoutContent>
  );
};

export default Index;
